<template>
  <fancyBackground>
    <div class="about">
      <headerSmall>About this Website</headerSmall>
      <box>
        <h2>The Vision</h2><br>It feels good when your guitar sounds the way you want it to. The only thing you need in
        order to enjoy some high quality tones is an audio interface, that’s it. The process of tone matching can be
        really frustrating and boring. With the endless parameters and choices given, you end up not playing the guitar
        but waste your time tweaking knobs. That’s what I am here for, I’ll do the boring part for you. Listen to my
        work, what you hear is what you get, the only variables that change are the guitar and the player.<br><br>
        In this site I will be sharing some guitar and bass presets for various plugins. There are plugins that I
        like working with, more than others. I tend to use the ones that offer the best tonal quality.(Stuff from <a
          href="http://www.positivegrid.com/" target="_blank"> Positive Grid</a>,<a href="http://www.igniteamps.com/"
          target="_blank"> Ignite Amps</a>,
        <a href="http://lepouplugins.blogspot.gr/" target="_blank"> Alain Poulin</a>, <a href="https://www.tseaudio.com/"
          target="_blank"> TSE Audio</a>,<a href="http://pvamps.blogspot.gr/" target="_blank"> Vadim Taranov</a>,<a
          href="http://nickcrowlab.blogspot.gr/" target="_blank"> Nick Crow</a>,
        <a href="https://tal-software.com/" target="_blank"> TAL</a>) I 'd like to thank the developers for offering
        their work for
        free.
      </box>

      <box>
        <h2>The Mentality of this Site</h2><br>
        No annoying popups about cookies and gdpr compliance. No newsletter subscriptions.
        No intrusive ads, spawned every minute, covering the whole page,
        stoping you from interacting with what has real value, the site's content.<br><br>
        This site doesn't need to spy on it's visitors with cookies, bombard them with e-mails and ads in order to
        generate revenue.
        The community that has been built naturally throughout the years is supporting this website actively.
        What truly matters is the quality of the site's content.
        If the content of a site makes people's lives better and easier, traffic and revenue will come organically.
      </box>

      <box>
        <h2>Standards</h2><br>
        All of my patches follow the K-14 level standard. While searching through tone clouds, libraries or internet
        patches, you notice that the amount of output you get is totally random. This could be harmful for headphone
        users. You can solve this issue by using a limiter after your chain. I believe following standards is a really
        good practice.
      </box>

      <box id="gdpr">
        <h2>GDPR compliance</h2><br>
        If you purchase a product from this site, your email will be saved.
        If you wish to remove your mail from this list simply <a href="/contact/">visit the contact page</a>
        and request to unsubscribe from the list using the mail that you want to be removed.
        If you use cryptocurrencies to make a purchase, no information of yours can be stored on the site.
        No other data is collected from the visitors/customers of this site.
      </box>

      <box id="legal">
        <h2 >Legal</h2><br>
        Some of the images in this site, may be the corresponding artist’s/band’s album artwork or logo relative to the
        type of tones the product is featuring. Under no means does this mean that this site is in any way affiliated
        with any of these artists or bands. The usage of those images is strictly for referencing purposes. All of
        those images are trademarked by their respective owners and we take no credit for any of that.
        All our products are named after the artists, bands or their albums only for descriptive purposes.
        All amp brands named are trademarked by their respective owners and are in no way affiliated with this site's
        products. You are purchasing a license to use these presets for your own personal and/or professional use. The
        presets are not for resale or any unlicensed distribution, free or compensated. This site simply seeks to
        re-create the sound of the amplifiers listed and any use of the brand names used is strictly for comparison and
        descriptive purposes as well.
      </box>
      <footerMain />
    </div>
  </fancyBackground>
</template>

<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";
import box from "@/components/Box.vue";

export default {
  name: "about",
  components: {
    fancyBackground,
    box,
    headerSmall,
    footerMain
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "About",
      // all titles will be injected into this template
      titleTemplate: this.title + " | ToneGod",
      meta: [
        {
          name: "description",
          content: this.meta_description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/about/"
        },
        {
          name: "robots",
          content: "noindex, nofollow"
        }
      ]
    };
  },
  data() {
    return {
      title: "About",
      meta_description: "About ToneGod and jimmy pickboy dimitris vasil whois",
      description: "About this site"
    };
  }
};
// console.log("TEST FROM ABOUT");
</script>
