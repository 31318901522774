<template>
  <fancyBackground>
    <div class="tutorial">
      <headerSmall>
        <template>
          Bias Preset Manager Application
        </template>
        <template slot="short">
          An app that allows you to manage Positive Grid's Bias Fx 2 presets
        </template>
      </headerSmall>
      <div class="center-preset" v-vpshow>
        <img src="https://i.imgur.com/pOSXJjP.png" />
      </div>
      <box>
        <h2>Bias Preset Manager Application</h2>
        <h3>(new version)</h3><br>
        I present you the new and upgraded version of the Bias Preset Importer Tool. <br>Now compatible with the
        second generation of Positive Grid's products. (Bias FX 2)<br><br>

        <strong>Features:</strong>
        <ul>
          <li>• Import multiple presets in any bank (including factory banks)</li>
          <li>• Export a preset and share it with your friends.</li>
          <li>• Rename banks (including factory banks)</li>
          <li>• Rename presets</li>
          <li>• Quickly move any preset to any bank</li>
          <li>• Delete presets</li>
          <li>• Star / unstar presets.</li>
        </ul>
        <br>
        No need to use the ToneCloud anymore. Manage your presets conveniently and freely. ❤️

        Available for Windows and Mac.<br><br>
        <p style="text-align: center;">⌨️ <a href="https://github.com/catman85/Bias-2-Importer-Exporter" target="_blank"
            rel="noopener noreferrer"> Github Link (New project)</a>.</p>
      </box>
      <div class="center-preset" v-vpshow>
      <iframe width="760" height="515" :src="youtubeEmbed('https://www.youtube.com/watch?v=a8FymvKUzvo')"
          frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
      <box>
        <h2>Bias Preset Importer Tool</h2>
        <h3>(old version)</h3><br>
        <img src="https://i.imgur.com/j6CqZFK.png" /><br>
        Simply run “Bias Importer.exe” and setup the directories. Now you can privately send presets to your
        friends without using the ToneCloud!<br><br>

        In the following Github link there are some TestFiles (a Bias Amp Model and a Bias FX preset) that you
        can use, to take this app for a test-drive. Just to be safe, backup the folders “Bias” and “BIAS_FX”
        from your documents.<br><br>

        • After Importing your preset, open up Bias FX and look in the “Factory Rock” bank.<br><br>
        • After Importing your preset, open up Bias Amp and look in the “Custom” bank.<br><br>

        This app requires a <a href="https://www.oracle.com/java/technologies/java-archive-javase10-downloads.html">Java
          Runtime Enviroment (10)</a> to run propely!<br>
        Only Windows compatible. Not compatible with Bias Amp 2 or Bias FX 2.<br><br>
        <p style="text-align: center;">⌨️ <a href="https://github.com/catman85/Bias-Importer" target="_blank"
            rel="noopener noreferrer"> Github Link (Old project)</a>.</p>
      </box>
      <box>
        <h2>🖥️ These projects are <i>free & open source</i></h2><br>
        What that means is that you can see the inner workings of them, verify the security of each line of code,
        compile them and run them by yourself.
        You can visit the <a href="/preset-installation-tutorials/">Preset Installation Tutorial page</a>
        and learn how these apps actually work.<br><br>
        If you don't want to deal with code simply click on the big yellow button and donate. I would <strong>really</strong> appreciate it.<br><br>

        <p style="text-align: center;">⌨️ <a href="https://github.com/catman85/Bias-2-Importer-Exporter" target="_blank"
            rel="noopener noreferrer"> Github Link (New project)</a>.</p>
        <p style="text-align: center;">⌨️ <a href="https://github.com/catman85/Bias-Importer" target="_blank"
            rel="noopener noreferrer"> Github Link (Old project)</a>.</p>
      </box>
      <div class="center-preset" v-vpshow>
        <button v-on:click="goTo('https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4RES63A2HPE74')"
          class="pushy-btn">Get the Apps</button>
      </div>
      <footerMain />
    </div>
  </fancyBackground>
</template>

<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";
import box from "@/components/Box.vue";

export default {
  name: "about",
  components: {
    fancyBackground,
    box,
    headerSmall,
    footerMain
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Bias Importer Tool",
      // all titles will be injected into this template
      titleTemplate: "%s | ToneGod",
      meta: [
        {
          name: "description",
          content: this.description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/bias-preset-importer-tool/"
        },
        {
          // The sub .vue files can overite these meta tags. We use vmid to ask vue-meta to overite the parent with the child
          vmid: "og:image",
          property: "og:image",
          content: "https://i.imgur.com/pOSXJjP.png"
        }
      ]
    };
  },
  data() {
    return {
      title: "Bias Importer Tool",
      description: "How to import Presets to Bias Amp, Bias FX 2"
    };
  }
};
</script>
