<template>
  <fancyBackground>
    <div class="Contact">
      <headerSmall>Contact Page</headerSmall>
      <section class="center-preset">
        <!-- TODO: add captha -->
        <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/contact/success/">
          <!-- honeypot bot trap (needs to be hidden)-->
          <!-- ATTENTION The hidden input field should have a name attribute called "form-name" and a value matching the name of the form, which in this case is "contact" -->
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>Email: <input type="text" name="email" required/></label>
          </p>
          <p>
            <label>Message: <textarea name="message" required></textarea></label>
          </p>
          <p class="center-preset">
            <button type=”submit” class="pushy-btn">Send Message</button>
          </p>
        </form>
      </section>
      <footerMain />
    </div>
  </fancyBackground>
</template>

<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";

export default {
  name: "contact",
  components: {
    fancyBackground,
    headerSmall,
    footerMain
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Contact",
      // all titles will be injected into this template
      titleTemplate: "%s | ToneGod",
      meta: [
        {
          name: "description",
          content: this.description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/contact/"
        }
      ]
    };
  },
  data() {
    return {
      form: {
        askPerson: ""
      },
      title: "Contact",
      description: "Contact Page"
    };
  }
};
</script>
