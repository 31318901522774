<template >
<div class="boxborder wordbox" v-vpshow>
    <!-- <div class="boxborder"></div> -->
    <p><slot></slot></p>
</div>
</template>

<script>
export default {
  name: "box"
};
</script>
