<template>
    <fancyBackground>
    <div class="submission">
      <headerSmall>Submission Failure</headerSmall>
      <footerMain />
    </div>
  </fancyBackground>
</template>

<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";

export default {
  name: "about",
  components: {
    fancyBackground,
    headerSmall,
    footerMain
  }
};
</script>
