<template>
  <fancyBackground>
    <div class="tutorial">
      <headerSmall>
        <template>
          The Site's Source Code
        </template>
        <template slot="short">
          Do you like this site? I am flattered! Here is some techy stuff
        </template>
      </headerSmall>
      <box>
        Techy Stuff ......
      </box>
      <div class="center-preset" v-vpshow>
        <button v-on:click="goTo('https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=64BB7VD4NSJGQ')"
          class="pushy-btn">Get the App</button>
      </div>
      <footerMain />
    </div>
  </fancyBackground>
</template>

<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";
import box from "@/components/Box.vue";

export default {
  name: "about",
  components: {
    fancyBackground,
    box,
    headerSmall,
    footerMain
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Vue Blog",
      // all titles will be injected into this template
      titleTemplate: "%s | ToneGod",
      meta: [
        {
          name: "description",
          content: this.meta_description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/get-the-source-code/"
        }
      ]
    };
  },
  data() {
    return {
      title: "Vue Blog",
      meta_description: "Vue Blog Headless CMS router source code project",
      description: "My Headless Vue CMS"
    };
  }
};
// console.log("TEST FROM ABOUT");
</script>
