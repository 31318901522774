<template>
    <fancyBackground>
        <div class="tutorial">
            <headerSmall>
                <template>
                    Preset Installation Tutorials
                </template>
                <template slot="short">
                    for Bias Amp, Bias FX, Line 6 Helix, and Other Vst plugins
                </template>
            </headerSmall>
            <box id="biasampTut">
                <h2>Installing Bias Amp 1 Models</h2><br>
                <h3>• Use the <a href="/bias-preset-importer-tool">Bias Importer Tool</a> I made!</h3><br>
                <!-- <hr> -->OR<br><br>
                <h3>• Manual Procedure:</h3><br>

                <h3>1.</h3> You will receive a folder with a weird name. This is the AMP model, simply paste it inside
                the
                GlobalPresets folder.<br><br>

                <h3>2.</h3> Inside the Bias folder you will find a file called “cate.idx”. Open it with a Text Editor
                and scroll
                at the bottom of it.<br><br>

                <img src="https://i.imgur.com/p8eUPOv.png" /><br><br>

                You need to append a certain piece of code at the end of the file.<br> You append the code between the
                brackets highlighted in the picture above.<br><br>
                <h4>“display_order” : X, where X is the previous value +1.</h4><br>
                <h4>“id” : “unique identifier”, where the unique identifier must match the name of the Bias Amp folder
                    name
                    you just purchased.</h4><br> If you are having trouble <a href="/contact/" target="_blank">contact
                    me!</a><br><br>

                <h3>Default Path for GlobalPresets folder:</h3><br>

                • (On Windows) “C:\Users\User Name\Documents\Bias\GlobalPresets\”<br><br>

                • (On Mac) “documents / bias / global presets” .<br><br>

                Each amp is a folder with a unique identifier as its name. Inside the Bias folder go to GlobalPresets:<br><br>
                <img src="https://i.imgur.com/hrI42Te.png" /><br>

                Simply paste the folder with that weird name in the pool with all the other folders inside
                GlobalPresets:<br><br>

                <img src="https://i.imgur.com/3iBy0us.png" /><br>

                <i>Note: A separate IR file is provided in case Bias Amp fails to upsample/downsample. In that case
                    re-import
                    the IR or use an external IR loader like NadIR.</i><br>
            </box>
            <box id="biasfxTut">
                <h2>Installing BIAS FX 1 Presets:</h2><br>
                <h3>• Use the <a href="/bias-preset-importer-tool">Bias Importer Tool</a> I made!</h3><br>
                <!-- <hr> -->OR<br><br>
                <h3>• Manual Procedure:</h3><br>
                <h3>1.</h3> You will receive a <b>“.Preset”</b> file. You need to copy and paste this file in the
                factory1 bank.<br><br>

                <h3>Default Path for the “factory1” folder:</h3><br>

                • (On Windows) “C:\Users\User Name\ Documents\BIAS_FX\ Presets\factory1\”)<br><br>

                • (On Mac) “/documents / bias fx / presets / factory1/”<br><br>

                <h3>2.</h3> You need to modify the contents of the <b>“preset.JSON”</b> file inside the factory1
                directory<br><br>
                Inside of factory1, there is a “preset” JSON file. There must be a piece of code inside this file
                similar to this…<br><br>
                <img src="https://i.imgur.com/SljclfG.png" /><br>
                for example to install the preset “JB DUAL LEAD.Preset” we add the following code at the end of the
                file<br><br>

                <h4>“display_order” : must be the previous value + 1</h4><br>

                <h4>“preset_uuid” : is a Random Unique Identifier. Just make something up! (it must have a certain
                    length
                    and the dashes must be placed in certain positions)</h4><br>
                <img src="https://i.imgur.com/qaCr3DY.png" /><br>
            </box>
            <box>
                <h2>Installing a custom Skin for my Bias Amp Models (You do this only once)</h2><br>
                <h3>1.</h3> Paste the .png files that you receive in the “TemplateImages” folder.<br><br>

                <h3>Default Path for the TemplateImages folder:</h3><br>

                • (on Windows) “C:\Program Files\Common Files\PositiveGrid\BIAS AMP
                Plugins(64bit)\Resources\TemplateImages”<br><br>

                • (on Mac) “Library / Audio / Plugins / Components / BIAS.component /
                Resources / TemplateImages” (Right
                click Bias.component, show package content)<br><br>

                <i>Note: If you want to use an amp that came with a custom skin inisde Bias FX, you need to create a
                    duplicate of this amp ,without a custom skin.</i><br>
                <i>Simply, inside Bias Amp open up the amp model you just purchased and click “Save As”,”OK”. That way
                    you
                    will create the same model but without a fancy skin. The Amp will now be visible inside Bias FX.</i>
            </box>
            <box id="helixTut">
                <h2>Installing Line 6 Helix Presets:</h2><br>
                You will receive a “.hlx” file. You import it like this… <br><br>
                <img src="https://i.imgur.com/926RfPK.png" /><br>
                If an Impulse Response file (“.wav”) is included in the package you recieved, import it like this…<br><br>
                <img src="https://i.imgur.com/8ngq3G3.png" /><br>
                When you open up your new preset, make sure that the IR block points to the new IR you just imported!<br><br>
                <img src="https://i.imgur.com/riYPd36.png" />
            </box>
            <box>
                <h2>Converting Line 6 Helix Presets to HX Stomp Presets and vice versa</h2><br>
                Line 6 <a href="https://line6.com/support/topic/18284-helix-firmware-280-available-spring-2019/">announced
                    HX Unity</a> which means you will be able to move presets between units without modifying the .hlx
                files.<br>
                I decided to upload some of the notes I made, after reverse engineering the contents of a .hlx file.<br>
                Special thanks to the community for testing the converted .hlx files I made and verifying they work.<br><br>
                <h3>HX Stomp Limitations:</h3><br>
                Stomp is limited to 6 blocks on Path 1 and 3 stomp assignments.<br><br>
                Stomp can handle up to 3 snapshots [snapshot0, snapshot1, snapshot2 ]<br><br>
                <h3>Error codes:</h3><br>
                • "Target is incompatible": means the json is broken<br><br>
                • "Incompatible target device type": means you have to modify the device number<br><br>
                • "A model used in the preset is not recognised": means you have to rename or remove a block, or update
                your firmware<br><br>

                <b>"device number"</b> must be specific and cannot be blank<br> <b>for Native: 2162689, 2162944<br> for
                    Stomp: 2162694</b><br><br>

                <h3>Block Renames between Helix Native and HX Stomp</h3><br>
                • In stomp we have multiple instances of "HelixStomp_AppDSPFlowInput" but in Native we have
                "HD2_AppDSPFlow1Input" and "HD2_AppDSPFlow2Input"<br><br>
                • In stomp we have "HelixStomp_AppDSPFlowOutputMain" and "HelixStomp_AppDSPFlowOutputSend" but in
                Native we have multiple instances of "HD2_AppDSPFlowOutput" (same thing twice)
            </box>
            <box id="vstTut">
                <h2>Installing VST plugin Presets</h2><br>
                The VST file format for storing single presets is “.FXP”. The package you get through email will
                include all the .FXP files needed for all the plugins in the chain. You will have to import the .FXP
                file for every plugin in the chain through your DAW, unless the plugin has some built in preset storage
                functionality. Alternatively you can use the pictures inside the package you receive to setup
                everything manually. Here is how you do it in Reaper…<br><br>
                <img src="https://i.imgur.com/kXAR6pX.png" /><br>
            </box>
            <footerMain />
        </div>
    </fancyBackground>
</template>

<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";
import box from "@/components/Box.vue";

export default {
  name: "about",
  components: {
    fancyBackground,
    box,
    headerSmall,
    footerMain
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "asfasfasf",
      // all titles will be injected into this template
      titleTemplate: this.title + " | ToneGod",
      meta: [
        {
          name: "description",
          content: this.meta_description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "http://www.tonegod.art/preset-installation-tutorials/"
        },
        {
          name: "robots",
          content: "index, nofollow"
        }
      ]
    };
  },
  data() {
    return {
      title: "Tutorials",
      meta_description:
        "How to install Bias Amp Bias FX Bias Pedal Presets. How to Convert Presets from Line 6 Helix to HX Stomp. Importing Line 6 Presets.",
      description: "Preset Installation Tutorials"
    };
  }
};
</script>
