<template>
  <fancyBackground>
    <!-- this div needed for the footer to work -->
    <div>
      <headerSmall>Contact me for a Guest Solo</headerSmall>
      <box>
        <h2>Info</h2><br>
        I can write a lead guitar solo for your track, remotely.
        I love making cool solos and improvising. It takes me around 2 weeks to complete a composition.
        I need a lot of time to analyze the track and come up with the best possible result.
        The cost of this process depends on the duration of the solo,
        and the musical complexity of the track. Usually my charges range from 40eur to 200eur.
        Sorry, I can't do this for free.
      </box>
      <box>
        <h2>How it works</h2><br>
        You send me the track that you want me to solo over.
        We talk about what styles of music you enjoy, what guitarists you like,
        what you want the solo to sound like.
        You send me 30% of the overall price we agreed upon via paypal, so that I can start working on it.
        I send you multiple clips and previews along the way.
        When you feel safe and you are happy with the result, you send me the rest.
        I then send you the rendered guitar track, along with the consolidated DI,
        in case you want to mix/reamp my playing to your liking.
      </box>
      <box>
        <h2>Contact</h2><br>
        If you are interested, <a href="/contact/">send me an email</a>,
        or <a href="http://m.me/jimmm.vasil.3">message me directly</a>
      </box>
      <box>
        <h2>Check out my previous work</h2>
      </box>
      <div class="center-preset" v-vpshow><iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/472381059&color=%23ac9494&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe></div>
      <!-- v-for="(link,i) in youtube" :key="i"  -->
      <!-- does not permit cross-origin framing. -->
      <div class="center-preset" v-vpshow>
        <iframe width="560" height="315" :src="youtubeEmbed('https://www.youtube.com/watch?v=UW3aADC0Cf8')" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe width="560" height="315" :src="youtubeEmbed('https://www.youtube.com/watch?v=tGhPHXH-gQk')" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe width="560" height="315" :src="youtubeEmbed('https://www.youtube.com/watch?v=XLQj082Z-1I')" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      <footerMain />
    </div>
  </fancyBackground>
</template>


<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";
import box from "@/components/Box.vue";

export default {
  name: "about",
  components: {
    fancyBackground,
    box,
    headerSmall,
    footerMain
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Session Guitar Solos",
      // all titles will be injected into this template
      titleTemplate: "%s | ToneGod",
      meta: [
        {
          name: "description",
          content: this.meta_description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/hire-me-for-a-solo/"
        },
        {
          name: "robots",
          content: "index, nofollow"
        }
      ]
    };
  },
  data() {
    return {
      title: "Session Guitar Solos",
      meta_description: "Session guitar work remote solo guest hire me.",
      description: "Contact me for a guest solo"
      // youtube: [
      //   "https://www.youtube.com/watch?v=tGhPHXH-gQk",
      //   "https://www.youtube.com/watch?v=UW3aADC0Cf8",
      //   "https://www.youtube.com/watch?v=XLQj082Z-1I"
      // ]
    };
  }
};
</script>
