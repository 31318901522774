<template>
  <fancyBackground>
    <!-- ATTENTION if you are going to use a single slot you must wrap the contents in a div -->
    <div>
      <headerSmall>
        <template>
          {{this.preset.title}}
        </template>
        <template v-if="this.preset.short_description" slot="short">
          {{this.preset.short_description}}
        </template>
      </headerSmall>

      <div class="center-preset" v-vpshow>
        <img :src="this.preset.image" />
      </div>

      <!-- <card :category=this.preset.category[0] :category2=this.preset.category[1]
            :data-image=this.preset.image>
            <h1 slot="header"></h1>
            <p slot="content"></p>
        </card> -->

      <!-- TODO: There is something wrong on ios safari (the youtube iframe goes left?), use browserstack to find out -->
      <box v-if="this.preset.description">
        <p v-html="this.preset.description">
        </p>
      </box>

      <!-- FIXME: all these mysterious parameters make no difference -->
      <div v-if="this.preset.youtube" class="center-preset" v-vpshow>
        <iframe v-if="this.preset.youtube[0]" width="560" height="315" :src="youtubeEmbed(this.preset.youtube[0])"
          frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        <iframe v-if="this.preset.youtube[1]" width="560" height="315" :src="youtubeEmbed(this.preset.youtube[1])"
          frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>

      <!-- FIXME: some videos show "video unavailable" on the rendered version (the vue-youtube package makes no difference?)(tame impala)-->
      <!-- FIXME: insecure mixed content detected http + https with vue-youtube -->
      <!-- FIXME: https://stackoverflow.com/questions/27573017/failed-to-execute-postmessage-on-domwindow-https-www-youtube-com-http
      Blocked loading mixed active content “http://www.youtube.com/iframe_api”
      Failed to execute ‘postMessage’ on ‘DOMWindow’: The target origin provided (‘https://www.youtube.com’) does not match the recipient window’s origin (‘https://5c964a4015e9ee0008a76dd4--adoring-shaw-f99267.netlify.com’).      
      (This is caused by our ad blocker) Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at https://googleads.g.doubleclick.net/pagead/id. (Reason: CORS request did not succeed) 
      (This is caused by our ad blocker) Loading failed for the <script> with source “https://static.doubleclick.net/instream/ad_status.js”-->
      <!-- <div v-if="this.preset.youtube" class="center-preset" v-vpshow>
        <youtube v-if="this.preset.youtube[0]" :video-id="getYoutubeIdFromUrl(this.preset.youtube[0])" ref="youtube">
        </youtube>
        <youtube v-if="this.preset.youtube[1]" :video-id="getYoutubeIdFromUrl(this.preset.youtube[1])" ref="youtube">
        </youtube>
      </div> -->

      <box v-if="this.preset.descriptionMid">
        <p v-html="this.preset.descriptionMid">
        </p>
      </box>

      <div v-if="this.preset.soundcloud" class="center-preset" v-vpshow>
        <iframe v-if="this.preset.soundcloud[0]" width="100%" height="166" scrolling="no" frameborder="no"
          allow="autoplay" :src="soundcloudEmbed(this.preset.soundcloud[0])">
        </iframe>
        <iframe v-if="this.preset.soundcloud[1]" width="100%" height="166" scrolling="no" frameborder="no"
          allow="autoplay" :src="soundcloudEmbed(this.preset.soundcloud[1])">
        </iframe>
      </div>

      <!-- Support for up to three paypal buttons per preset -->
      <div v-if="this.preset.paypal" class="center-preset" v-vpshow>
        <button v-if="this.preset.paypal[1]" v-on:click="redirect(0)"
          class="pushy-btn">{{this.preset.buttonDescription[0]}}</button>
        <button v-else v-on:click="redirect(0)" class="pushy-btn">Gimme Tone 🔥</button>
        <!-- if there is no button description use this default -->

        <!-- <box> + THALL<br> -->
        <button v-if="this.preset.paypal[1]" v-on:click="redirect(1)"
          class="pushy-btn">{{this.preset.buttonDescription[1]}}</button>
        <!-- </box> -->
        <button v-if="this.preset.paypal[2]" v-on:click="redirect(2)"
          class="pushy-btn">{{this.preset.buttonDescription[2]}}</button>
      </div>
      <!-- TODO: a rel="nofollow" ,alt-->
      <!-- TODO: https://web.dev/fast/avoid-invisible-text -->
      <!-- TODO: multithreading with webworkerks? -->
      <!-- Support for up to 2 categories per preset -->
      <div class="preset" v-vpshow>
        <div class="card-category ">
          <button class="button" :class="this.preset.category[0].toLowerCase().trim().split(' ').join('')"
            type='button'>{{this.preset.category[0].toLowerCase()
            | capitalize}}</button>
        </div>
        <div v-if="this.preset.category[1]" class="card-category2">
          <button class="button" :class="this.preset.category[1].toLowerCase().trim().split(' ').join('')"
            type='button'>{{this.preset.category[1].toLowerCase()
            | capitalize}}</button>
        </div>
      </div>
      <box v-if="this.preset.descriptionBot">
        <p v-html="this.preset.descriptionBot">
        </p>
      </box>
      <footerMain />

    </div>

  </fancyBackground>
</template>



<script>
// @ is an alias to /src
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";
// import card from "@/components/Card.vue";

import box from "@/components/Box.vue";
import { data } from "@/assets/data.js";

export default {
  name: "preset",
  components: {
    fancyBackground,
    headerSmall,
    box,
    // card,
    footerMain
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.preset.title,
      // all titles will be injected into this template
      titleTemplate: "%s | ToneGod",
      meta: [
        {
          name: "description",
          content: this.preset.meta_description
        },
        {
          property: "og:title",
          content: this.preset.title
        },
        {
          property: "og:description",
          content: this.preset.short_description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/preset/" + this.preset.id + "/"
        },
        {
          // The sub .vue files can overite these meta tags. We use vmid to ask vue-meta to overite the parent with the child
          vmid: "og:image",
          property: "og:image",
          content: this.preset.image
        }
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.tonegod.art/preset/" + this.preset.id + "/"
        }
      ]
    };
  },
  data() {
    return {
      posts: data,
      preset: {},
      videoId: "lG0Ys-2d4MA"
    };
  },
  created() {
    // search the db for the id from the url and populate the preset object
    let id = this.$route.params.id;
    for (let p in this.posts) {
      let dataId = this.posts[p].id;
      if (id == dataId) {
        this.preset = this.posts[p];
        break;
      }
    }
  },
  mounted() {
    if (this.preset.popularity > 4) {
      // Just to add some realism
      this.$awn.success("Someone got this tone recently. 😇");
    }
  },
  // beforeRouteUpdate(to, from, next) {
  //   // https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
  //   // react to route changes...
  //   // don't forget to call next()
  // },
  methods: {
    redirect(num) {
      // https://matteogabriele.gitbooks.io/vue-analytics/content/
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
      this.$ga.event({
        eventCategory: "BigYellowButton",
        eventAction: "Click",
        eventLabel: this.preset.id
      });

      // ATTENTION if the link don't start with https://www redirection fails and the url is appended to the current one
      // window.location.href = this.preset.paypal[num];
      window.open(this.preset.paypal[num], "_blank");
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>

<style lang="scss">
// $awn-success-color: #ffffff !default;
// $awn-toast-width: 20em !default;

@import "~vue-awesome-notifications/dist/styles/style.scss";

#awn-modal-wrapper {
  bottom: 15em;
}
</style>
