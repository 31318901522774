<template>
  <fancyBackground>
    <div class="faq">
      <headerSmall>Frequently Asked Questions</headerSmall>
      <box>
        <h2>How will I get your patches?</h2><br>
        Once payment via PayPal is completed, you will be redirected to a dropbox page, where you will get a
        package with all the “.fxp”, “.hlx” files, Impulse Responses, links to the software used, documentation, pics
        and all the necessary files.
      </box>
      <box>
        <h2>How will I import your presets?</h2><br>
        In case any of the following steps fail, you can use the detailed pics that I will send you. This allows you to
        adjust all of the variables manually. Visit the <a href="/preset-installation-tutorials/" target="_blank">Preset Installation Tutorials Page</a>.<br> If you have any issue <a href="/contact/">contact me.</a>
        <ul><li><a href="/preset-installation-tutorials/#biasampTut" target="_blank"><h3>• Bias Amp Models</h3></a></li>
        <li><a href="/preset-installation-tutorials/#biasfxTut" target="_blank"><h3>• BIAS FX Presets</h3></a></li>
        <li><a href="/preset-installation-tutorials/#helixTut" target="_blank"><h3>• Line 6 Helix</h3></a></li>
        <li><a href="/preset-installation-tutorials/#vstTut" target="_blank"><h3>• Other VST plugins</h3></a></li>
        </ul>
      </box>
      <box>
        <h2>Why do you sell your patches and don’t give them for free?</h2><br>
        Because I’m a bad person. <a href="/?free=true">Here is some free stuff</a> I giveaway.
      </box>
      <box>
        <h2>How do I know your patches sound good?</h2><br>
        Just check the samples I have uploaded on my <a href="https://soundcloud.com/ocarinainmypants">soundcloud</a>
        or <a href="https://www.youtube.com/channel/UCzg4SWk_mrpKA31s2dT5Clw">youtube</a> account and let your ears be
        the judge.
        What you hear, is what you get! If you are still hesitating, <a href="/contact/">contact me</a> and I will
        reamp some DIs for you. That way you know what the presets will sound like, through your rig.
      </box>
      <box>
        <h2>Who are you?</h2><br>
        I am a guitar nerd. I study Digital Systems and Digital Signal Processing at the University Of Piraeus, in
        Greece.
      </box>
      <footerMain />
    </div>
  </fancyBackground>
</template>

<script>
import fancyBackground from "@/components/Background.vue";
import headerSmall from "@/components/HeaderSmall.vue";
import footerMain from "@/components/Footer.vue";
import box from "@/components/Box.vue";

export default {
  name: "faq",
  components: {
    fancyBackground,
    headerSmall,
    footerMain,
    box
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "F.A.Q.",
      // all titles will be injected into this template
      titleTemplate: "%s | ToneGod",
      meta: [
        {
          name: "description",
          content: this.meta_description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/faq/"
        },
        {
          name: "robots",
          content: "noindex, nofollow"
        }
      ]
    };
  },
  data() {
    return {
      title: "F.A.Q.",
      meta_description:
        "Asked Questions ToneGod and jimmy pickboy dimitris vasil presets helix whois trust",
      description: "Frequently Asked Questions"
    };
  }
};
// console.log("TEST FROM ABOUT");
</script>
